import React, { useState } from "react";
import styled from "styled-components";
import { Squash as Hamburger } from "hamburger-react"

const Header = ({height, onHomeButton}) => {
    const [isAbout, setAbout] = useState(false);

    return (
      <NavBar style={{
        height: height,
        zIndex: "10000"
    }}>
        <Button onClick={() => {
          onHomeButton();
        }}>
          Project Verse
        </Button>
        <Hamburger 
          rounded 
          size={28}
          toggled={isAbout}
          toggle={setAbout} />
      </NavBar>
    )
}

const NavBar = styled.nav`
    position: absolute;
    vertical-align: top;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 1%;
    letter-spacing: 1px;
`

const Button = styled.button`
    border: 0;
    background-color: transparent;
    color: white;
    font-family: 'Comfortaa', cursive;
    font-size: medium;
    opacity: 1.0;
    transition: opacity .25s ease;
    user-select: none;
    :hover {
        opacity: 0.2;
        transition: opacity .25s ease;
        cursor: pointer;
    }
`

export default Header;