import React, { useState } from "react";
import { useFBX } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useSpring, config, animated } from "@react-spring/three"

// export default function Model(props) {
//     const group = useRef();
//     const {nodes, materials} = useGLTF(process.env.PUBLIC_URL + "/city-model.gltf");
//     return (
//         <group ref={group} {...props} dispose={null} scale={0.0001}>
//             {
//                 nodes..array.map(node => {
//                     return <mesh castShadow receiveShadow geometry={node.geometry}>
//                         <meshLambertMaterial color={"white"} />
//                     </mesh>
//                 })
//             }
//         </group>
//     )
// }

// useGLTF.preload(process.env.PUBLIC_URL + "/city-model.gltf")
export default function Model({position, scale, path, opacity}) {
    const [hover, setHover] = useState(false);
    const fbx = useFBX(process.env.PUBLIC_URL + "/" + path);

    const {currScale} = useSpring({
        currScale: hover ? scale * 1.5 : scale,
        config: config.wobbly
    });

    useFrame(({ clock }) => {
        const a = clock.getElapsedTime();
        fbx.rotation.y = a * 0.1;
      });

    return <animated.primitive
        object={fbx}
        position={position}
        scale={currScale}
        onPointerEnter={() => setHover(true)}
        onPointerLeave={() => setHover(false)}>
            <animated.meshBasicMaterial color={(0.8, 0.5, 0.2, 0.1)} opacity={0.4}/>
        </animated.primitive>
}