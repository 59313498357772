import React from "react";
import styled from "styled-components";

const Footer = (props) => {
  return (
    <Container style={{
      height: props.height
    }}>
      <Text>by Ilkin Mammadli Productions.</Text>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  vertical-align: bottom;
  top: 90vh;
  left: 0;
  right: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 2%;
  letter-spacing: 1px;
`

const Text = styled.text`
  border: 0;
  background-color: transparent;
  color: white;
  font-family: 'Comfortaa', cursive;
  font-size: x-small;
  user-select: none;
`

export default Footer;