import React from "react";
import { animated } from "@react-spring/three";
//import { useFrame } from "@react-three/fiber";
import { Billboard, Text } from "@react-three/drei";

export default function TitleText({text, position, scale}) {
  // const [visible, setVisible] = useState(true);

  // const opacity = useSpring({
  //   opacity: visible ? 1.0 : 0.0,
  //   config: config.gentle
  // })
  
  const AnimatedText = animated(Text);

  return (
    <Billboard follow>  
      <AnimatedText
        // onPointerEnter={() => { setVisible(false) }}
        // onPointerLeave={() => { setVisible(true) }}
        position={position}
        scale={scale}
        fontSize={1}
        font={"https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff"}
      > {text} </AnimatedText>
    </Billboard>
  )
}