import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Canvas } from "@react-three/fiber"
import { Image, OrbitControls, Text } from "@react-three/drei"
//import { useDrag, useHover, useWheel } from "@use-gesture/react"
import { useSprings } from "@react-spring/three"
//import { interpolate } from "react-spring"

import Header from "./components/Header"
import Footer from "./components/Footer"
//import Box from "./components/Box"
import TitleText from "./components/TitleText"
import Model from "./components/Model"
//import Loader from "./components/Loader"
import Camera from "./components/Camera"

import { MdNavigateNext, MdNavigateBefore, MdOutlineFilterCenterFocus } from "react-icons/md"
import { IconContext } from "react-icons"

const projectList = [
  {name: "Vast Engine", color: "green", position: {x: 0, y: 0}},
  {name: "Morph Library", color: "red", position: {x: 20, y: 0}},
  {name: "VR Calculator", color: "blue", position: {x: 40, y: 0}}
]

export default function App() {
  const [dist, setDist] = useState(40);
  const [current, setCurrent] = useState(0);
  const [focus, setFocus] = useState(false);
  const [rOpacity, setROpacity] = useState(1.0);
  const [lOpacity, setLOpacity] = useState(0.0);

  useEffect(() => {
    if (lOpacity !== 1.0 && current !== 0) {
      setLOpacity(1.0);
      setDist(40);
    }
    if (rOpacity !== 1.0 && current !== projectList.length - 1) {
      setROpacity(1.0);
    }
  })

  const [props, api] = useSprings(
    projectList.length,
    index => ({
      pos: [focus ? -3.5 : 0, (index * dist - dist * current), -5],
      config: {mass: 40, tension: 200, friction: 200}
    })
  )

  api.start(index => ({
    pos: [focus ? -3.5 : 0, (index * dist - dist * current), -5],
    config: {mass: 35, tension: 150, friction: 125}
  }));

  // const moveModel = useSpring({
  //   y: focus ? 0.0 : -3.5,
  //   config: config.gentle
  // })


  // Dragging code
  /*const bind = useDrag(({down, movement: [mx, my]}) => {
    const mScale = 0.01;
    setPos({x: mScale * mx, y: pos.y});
    projectList.forEach((item, i) => {
      item.position.x = distDiff * (i - 1) + pos.x;
      item.position.y = pos.y;
    })
  })*/



  return (
    <Body>
      <Wrapper>
      <Header height="10vh" onHomeButton={() => {setFocus(false)}}/>
        <Canvas style={{
          position: "absolute"
        }}>
          <Camera position={[0, 1, 7]} rotation={[0, 0, 0]} />
          {false ? <OrbitControls enableZoom={true} enablePan={false} rotateSpeed={0.5} target={props.at(current).pos.get()} up={[50, 100, 500]} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} /> : undefined}
          <ambientLight intensity={0.5} /> 
          <directionalLight position={[-2, 5, 2]} intensity={1}/>
          <group opacity={0.1} onClick={() => {
            // On Model clicked
            // Switch between model view to detailed view
            if (focus) {
              setFocus(false)
            } else {
              setFocus(true)
            }
          }}>
            <group position={[1.6, 1.5, 0]}>
              {/* Detailed section with images, description, and links */}
              <Text maxWidth={3}>
                Helluva text is simply dummy text of the printing and typesetting 
                industry. Lorem Ipsum has been the industry's standard dummy 
                text ever since the 1500s, when an unknown printer took a 
                galley of type and scrambled it to make a type specimen book. 
                It has survived not only five centuries, but also the leap into 
                electronic typesetting, remaining essentially unchanged. It was 
                popularised in the 1960s with the release of Letraset sheets 
                containing Lorem Ipsum passages, and more recently with desktop 
                publishing software like Aldus PageMaker including versions of 
                Lorem Ipsum.
              </Text>
              <Image position={[0.5, -1.8, 0]} url="/VastEngine.png" scale={[4, 2]}/>
              <TitleText position={[-0.1, 1, 0.0]} scale={0.45} text={projectList[current].name} />
            </group>
            {props.map((item, index) => (
              <Model position={item.pos} scale={0.2} path={"city-model" + index + ".fbx"}/>
              ))}
          </group>
        </Canvas>
        <Navigation>
          <NextButton onClick={() => {
            if (current - 1 >= 0) {
              setCurrent(current - 1);
              if (current - 1 === 0) {
                setLOpacity(0.0)
              }
            }
          }}>
            <IconContext.Provider value={{ color: "white", size: "50px", style: { opacity: lOpacity }}}>
                <MdNavigateBefore/>
            </IconContext.Provider>
          </NextButton>
          <NextButton onClick={() => {
            setFocus(!focus);
          }}>
            <IconContext.Provider value={{ color: "white", size: "50px"}}>
                <MdOutlineFilterCenterFocus/>
            </IconContext.Provider>
          </NextButton>
          <NextButton onClick={() => {
            if (current + 1 < projectList.length) {
              setCurrent(current + 1);
              if (current + 1 === projectList.length - 1) {
                setROpacity(0.0);
              }
            }
          }}>
            <IconContext.Provider value={{ color: "white", size: "50px", style: { opacity: rOpacity } }}>
                <MdNavigateNext/>
            </IconContext.Provider>
          </NextButton>
        </Navigation>
      <Footer height="10vh"/>
      </Wrapper>
    </Body>
	)
}

const Body = styled.div`
  //background-image: linear-gradient(rgb(57, 180, 117), rgb(12, 170, 89));
  /* background: rgb(61,202,133);
  background: linear-gradient(0deg, rgba(61,202,133,1) 20%, rgba(26,179,56,1) 44%, rgba(4,101,106,1) 87%); */
  background-color: #1F1F1F;
  color: aliceblue;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
`

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  z-index: 0;
`

const NextButton = styled.div`
  position: relative;
  //z-index: 10000;
  opacity: 1.0;
  transition: opacity .25s ease;
  background-color: transparent;
  :hover {
    opacity: 0.7;
    transition: opacity .25s ease;
  }
`
const Navigation = styled.div`
  position: absolute;
  width: 150px;
  top: 80%;
  left: calc(50% - 75px);
  display: flex;
  justify-content: space-between;
  align-items: center;
`